import { lazy } from "react";
import PaypalCancelComponent from "../components/pay-with-paypal/cancelled";
import PaypalErrorComponent from "../components/pay-with-paypal/error";
import NotFoundComponent from "../components/pay-with-paypal/notFound";
import PaypalSuccessComponent from "../components/pay-with-paypal/thankyou";

const HomePage = lazy(() => import("../containers/home/HomePage"));
const ApplyPage = lazy(() => import("../containers/apply/ApplyPage"));
const ServicesPage = lazy(() => import("../containers/services/ServicesPage"));
const ServicesCheckoutPage = lazy(() =>
  import("../containers/servicesCheckout/ServicesCheckoutPage")
);
const FaqV2Page = lazy(() => import("../containers/faq/FaqV2Page"));
const AboutPage = lazy(() => import("../containers/about/AboutPage"));
const ConfirmationPage = lazy(() =>
  import("../containers/confirmation/confirmation")
);
const HowItWorks = lazy(() => import("../components/home/howItWorks"));
const AboutUs = lazy(() => import("../components/home/aboutUs"));
const FAQ = lazy(() => import("../components/home/faq"));
const Steps = lazy(() => import("../components/steps/steps"));
const ThankYou = lazy(() => import("../components/thankyou/thankyou"));
const ThankYouMyConnect = lazy(() =>
  import("../components/thankyou/thankyoumyconnect")
);
const PendingApproval = lazy(() =>
  import("../components/thankyou/pendingApproval")
);
const ErrorComponent = lazy(() => import("../components/error/error"));
const CancelComponent = lazy(() => import("../components/error/cancelled"));
const ErrorStateComponent = lazy(() =>
  import("../components/error/errorState")
);
const ZipRedirect = lazy(() => import("../components/steps/zipRedirect"));
const ZipProcessing = lazy(() => import("../components/steps/zipProcessing"));
// const PrivacyPolicy = lazy(() =>
//   import("../components/privacy-policy/privacyPolicy")
// );
// const Terms = lazy(() => import("../components/terms/terms"));
const Application = lazy(() =>
  import("../components/application-form/applicationForm")
);

const Confirm = lazy(() => import("../components/thankyou/confirm"));
const PayWithPaypal = lazy(() =>
  import("../components/pay-with-paypal/payWithPaypal")
);

export const ROUTES = [
  {
    path: "/",
    component: HomePage,
    title: "Lets us help you make your move as easier as possible"
  },
  {
    path: "/apply",
    component: ApplyPage,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/services",
    component: ServicesPage,
    title: "Rental services to help you out"
  },
  {
    path: "/services/checkout",
    component: ServicesCheckoutPage,
    title: "Rental services for aussie renters"
  },
  {
    path: "/faqs",
    component: FaqV2Page,
    title: "Helping aussies moves since 2017"
  },
  {
    path: "/about",
    component: AboutPage,
    title: "Making moving easier for renters"
  },
  {
    path: "/confirmation",
    component: ConfirmationPage,
    title: "Making moving easier for renters - Confirmation"
  },
  {
    path: "/how-it-works",
    component: HowItWorks,
    title: "How it Works - Incola Rental Bond. Move Now. Pay Later."
  },
  {
    path: "/about-us",
    component: AboutUs,
    title: "About Us - Incola Rental Bond. Move Now. Pay Later."
  },
  {
    path: "/faq",
    component: FAQ,
    title: "FAQ - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/application",
    component: Application,
    title: "Application - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/confirm",
    component: Confirm,
    title:
      "Application Confirmation - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/process-bond-application",
    component: Steps,
    title: "Sign up - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/thankyou",
    component: ThankYou,
    title: "Submitted - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/thank-you",
    component: ThankYouMyConnect,
    title: "Thank You - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/pending-approval",
    component: PendingApproval,
    title: "Pending Approval - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/redirect",
    component: ZipRedirect,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/processing",
    component: ZipProcessing,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/processing/:state",
    component: ZipProcessing,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/error",
    component: ErrorComponent,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/cancel",
    component: CancelComponent,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/error/:state",
    component: ErrorStateComponent,
    title: "Incola Rental Bond - Move Now. Pay Later."
  },
  // {
  //   path: "/privacy",
  //   component: PrivacyPolicy,
  //   title: "Privacy Policy - Incola Rental Bond - Move Now. Pay Later."
  // },
  // {
  //   path: "/terms",
  //   component: Terms,
  //   title: "Terms - Incola Rental Bond - Move Now. Pay Later."
  // },
  {
    path: "/pay-with-paypal",
    component: NotFoundComponent,
    title: "Pay With Paypal - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/pay-with-paypal/:transactionId",
    component: PayWithPaypal,
    title: "Pay With Paypal - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/paypal/cancelled",
    component: PaypalCancelComponent,
    title: "Transaction Cancelled - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/paypal/error",
    component: PaypalErrorComponent,
    title: "Error - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/paypal/success",
    component: PaypalSuccessComponent,
    title: "Success - Incola Rental Bond - Move Now. Pay Later."
  },
  {
    path: "/404",
    component: NotFoundComponent,
    title: "Page not Found - Incola Rental Bond - Move Now. Pay Later."
  }
];

export const DEFAULT_TITLE = "Incola Rental Bond - Move Now. Pay Later.";
