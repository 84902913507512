import FbIcon from "../images/menu-landing-pages-facebook.svg";
import InstaIcon from "../images/menu-landing-pages-instagram.svg";
import TwitterIcon from "../images/menu-landing-pages-twitter.svg";

import FbIconPS from "../images/footer-post-signup-facebook.svg";
import InstaIconPS from "../images/footer-post-signup-instagram.svg";
import TwitterIconPS from "../images/footer-post-signup-twitter.svg";

export const BASIC_SIGNUP_STEPS = [
  {
    text: "Hey! it’s Julia. Let’s get started.",
    back: false,
    buttonText: "Next"
  },
  {
    text: "Where are you going to live?",
    back: true,
    buttonText: "Next"
  },
  {
    text: "How much is your bond?",
    back: true,
    buttonText: "Next"
  },
  {
    text: "Income",
    back: true,
    buttonText: "Next"
  },
  {
    text: "Estimated payment schedule.",
    back: true,
    buttonText: "Next"
  },
  {
    text: "Mobile Number",
    back: true,
    buttonText: "Next"
  }
];

export const SIGNUP_STEPS = [
  ...BASIC_SIGNUP_STEPS,
  {
    text: "Sign up",
    back: true,
    buttonText: "Sign Up"
  }
];

export const NEW_APPLICATION_SIGNUP_STEPS = [...BASIC_SIGNUP_STEPS];

export const LOGGED_IN_MENU = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "How it Works",
    link: "/how-it-works"
  },
  {
    name: "FAQ",
    link: "/faq"
  },
  {
    name: "About",
    link: "/about-us"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "For Agencies",
    link: "/agencies"
  },
  {
    name: "separation",
    link: "/"
  }
];

export const MENU_PRE_SIGNUP = [
  ...LOGGED_IN_MENU,
  {
    name: "Log in",
    link: "/login"
  }
];

export const MENU_POST_SIGNUP = [
  ...LOGGED_IN_MENU,
  {
    name: "Log out",
    link: "/logout"
  }
];

export const HOME_MENU = [
  {
    name: "Home",
    link: "/apply"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "How it Works",
    link: "/how-it-works"
  },
  {
    name: "FAQ",
    link: "/faq"
  },
  {
    name: "About",
    link: "/about-us"
  },
  // {
  //   name: "separation",
  //   link: "/"
  // },
  // {
  //   name: "For Agencies",
  //   link: "/agencies"
  // },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "Apply for Bond",
    link: "#",
    type: "redirect"
  }
  // {
  //   name: "Log in",
  //   link: "/login"
  // },
  // {
  //   name: "separation",
  //   link: "/"
  // },
  // {
  //   name: "Ask Questions",
  //   link: "#",
  //   type: "function"
  // }
];

export const HOME_MENU_V2 = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "Services",
    link: "/services"
  },
  {
    name: "separation",
    link: "/"
  },
  {
    name: "FAQ",
    link: "/faqs"
  },
  {
    name: "About",
    link: "/about"
  }
];

export const FOOTER_MENU = [
  {
    name: "Facebook",
    icon: FbIcon,
    link: "https://www.facebook.com/incola.it/"
  },
  {
    name: "Instagram",
    icon: InstaIcon,
    link: "https://www.instagram.com/incolabond/"
  },
  {
    name: "Twitter",
    icon: TwitterIcon,
    link: "https://twitter.com/Incola_aus"
  }
];

export const FOOTER_MENU_LOGGEDIN = [
  {
    name: "Facebook",
    icon: FbIconPS,
    link: "https://www.facebook.com/incola.it/"
  },
  {
    name: "Instagram",
    icon: InstaIconPS,
    link: "https://www.instagram.com/incolabond/"
  },
  {
    name: "Twitter",
    icon: TwitterIconPS,
    link: "https://twitter.com/Incola_aus"
  }
];

export const GOOGLE_MAPS_LINK =
  "https://maps.googleapis.com/maps/api/staticmap?";
export const GOOGLE_PLACES_LINK =
  "https://maps.googleapis.com/maps/api/place/details/json?fields=geometry,address_components";
export const GOOGLE_AUTOCOMPLETE_LINK =
  "https://maps.googleapis.com/maps/api/place/autocomplete/json?types=address";
export const GOOGLE_MAPS_KEY = "AIzaSyAmwA8tw1ZXG8fo16T3ymx2HY3Q1gw6dwU";

export const SERVICE_URL = "https://api.incola.com.au/api/v2/";
export const ESERVICE_URL = "https://service.incola.com.au/";
// export const ESERVICE_URL = "http://localhost:8000/";

export const ZIP_URL_SANDBOX =
  "https://api.sandbox.zipmoney.com.au/merchant/v1/";

export const FAQS = [
  {
    q: "What states does Incola operate in?",
    a:
      "We are available in New South Wales, Queensland, Victoria, South Australia and Australian Capital Territory."
  },
  {
    q: "What does Incola do?",
    a:
      "Incola is a one of kind solution that helps the average Australian move. We know moving can be a stressful situation and we allow you to first connect your utilities, we offer everything from AGL to Red Energy, Internet to Electricity. Upon connecting we link you with our partner where we offer you an interest-free loan for 6 weeks on your rental bond up to $2000."
  },
  {
    q: "How long does the process take?",
    a:
      "We ask that from connecting your utilities to getting your bond paid we ask to give at least 72 hours before you move. The process will take about 30 minutes."
  },
  {
    q: "Queries regarding repayments",
    a: "Repayments are done all through our finance partner Paypal."
  },
  {
    q: "Do I have to connect 2 utilities?",
    a:
      "Yes, to make our business viable to the public we ask you to connect 2 utilities - we give you full options and don't ask you to choose one provider over another. If you don't get a message for finance, it'll be because 2 utilities were not connected."
  },
  {
    q: "I’ve connected – next steps?",
    a:
      "Great! You'll then get a text/email from our team asking for your rental bond amount - please respond and you'll then receive our link to get finance."
  },
  {
    q: "Paying the rental bond – what does Incola need?",
    a:
      "Once accepted for finance, Incola needs your rental bond BPay details - this includes the biller code and reference number which are specific to your move."
  },
  {
    q: "Support and customer service?",
    a:
      "Incola is available between 9-5 pm Monday through Friday on email at support@incola.com.au and on the phone between 12-2 pm Monday through Friday."
  },
  {
    q: "What if I don’t get approved for finance?",
    a:
      "Incola can look at other options for you if you don't get approved for finance."
  },
  // {
  //   q: "Are there any fees involved?",
  //   a:
  //     "There are zero fees to connect your utilities and a one-off fee of $50 if you get approved for finance."
  // },
  {
    q: "Does Incola pay my bond straight to the rental bond board?",
    a: "Yes, we do."
  },
  {
    q: "What are the minimum and maximum rental bonds you pay?",
    a: "Incola pays bonds between $1000-$2000."
  },
  {
    q: "How quickly can you pay my bond?",
    a:
      "As soon as you have gone through our whole process, your bond will be paid within the hour and you will receive a remittance."
  },
  {
    q: "I’m on Centrelink – will I get approved?",
    a:
      "You will not get approved unless you have other income streams coming in."
  },
  {
    q: "Do I have to tell my real estate agent I’m using Incola?",
    a:
      "No, you don't! We simply pay the bond on your behalf so no need to tell them!"
  },
  {
    q: "Can you pay straight to my real estate?",
    a: "In some cases, we do - please contact us on support@incola.com.au"
  },
  {
    q: "What utilities can I connect?",
    a: "You can connect Internet, Gas, Water, Electricity, PayTv"
  },
  {
    q: "Can my partner do the finance & I connect the utilities?",
    a: "Yes, they can!"
  },
  // {
  //   q: "What happens if I get declined by MoneyMe?",
  //   a:
  //     "If you are declined, please contact MoneyMe directly 1300329037 and quote your application number."
  // },
  {
    q: "Once approved do I receive the cash?",
    a:
      "No, we either pay straight to the rental bond board or provided we have a tenancy agreement we pay to the real estate."
  },
  {
    q:
      "Do I have to have be a Permanant Resident or Citizen of Australia to apply?",
    a: "Yes, we don’t accept finance for anyone that isn’t a PR or citizen."
  }
];

export const FAQ_V2 = {
  GENERAL: [
    {
      q: "Support and customer service?",
      a:
        "Incola is available between 9-5 pm Monday through Friday by email at support@incola.com.au and We aim to respond within 1 business day."
    },
    {
      q: "What states does Incola operate in?",
      a:
        "We are available in New South Wales, Queensland, Victoria, South Australia and Australian Capital Territory."
    }
  ],
  MOVING: [
    {
      q: "When should I call a moving company?",
      a: "The best timeframe is from four to six weeks beforehand"
    },
    {
      q: "How do I obtain a moving quote?",
      a:
        "There are several ways you can source a price for your move. This will ensure the moving company can assess the access to your property by estimating the time required for collection."
    },
    {
      q: "What items are you unable to move?",
      a:
        "We are unable to move any explosive, flammable, illegal, perishable or environmentally harmful. We are always here to help so don't hesitate to give one of our experts a call if you're not sure. \n\nIf you are in doubt please contact us."
    },
    {
      q: "How long is my quote valid for?",
      a:
        "valid for 30 days from the date of quote. We do however suggest you advise your predicted moving date and we can include dates on the quote to ensure the price is fixed."
    },
    {
      q: "What if your home is not ready when goods are due for delivery?",
      a:
        "We have storage facilities in Australia wide allowing for the transfer of your goods to store for holding until required."
    },
    {
      q: "Does the Moving company dismantle and re-assemble items?",
      a:
        "Dismantling and re-assembly of beds will be included in our quote. However, there are other items requiring this service such as desks, large cabinets, flatpack furniture, gym sets shelving and other specialist items which cannot be transported assembled."
    },
    {
      q: "Does the moving company pack personal effects?",
      a:
        "Depending on your requirements we can complete all your packing. We do provide cartons for personal items such as clothing for you to pack."
    },
    {
      q: "Should I take out moving insurance?",
      a:
        "We offer you our skills and expertise in performing our professional services to minimize risk and in addition, we offer you the peace of mind and assurance of protection against accidents and forces of nature outside our control.\n\nWe highly recommend taking out removal insurance for precious items."
    },
    {
      q: "What should I do with my pets?",
      a:
        "Moving can be a stressful experience for pets. We recommend arranging for a pet-sitter to look after your pets away from the moving locations on moving day"
    },
    {
      q: "Can I help during the move?",
      a:
        "You're able to help carry boxes, which can often make the move faster and cheaper."
    },
    {
      q: "What extra precautions should I consider?",
      a:
        "We take maximum precautions and place great importance on treating your belongings with the appropriate care they require for a safe and secure move.\n\nThis includes specialty protective equipment such as blankets and trolleys.\n\nNonetheless, accidents inevitably occur on rare occasions."
    },
    {
      q: "Do you have a cancellation policy?",
      a:
        "Bookings may be cancelled (or rescheduled) more than 24 hours before their start time without penalty and with a full refund of the deposit.\n\nBookings cancelled less than 24 hours before their start time will incur a loss of the deposit."
    },
    {
      q: "How long will the move take?",
      a:
        "There are a wide range of factors that influence how long a move will take.\n\nAs an estimate:",
      li: [
        "1 bedroom home: 2-4 hours",
        "2 bedroom home: 4-6 hours",
        "3 bedroom home: 5-7 hours"
      ]
    },
    {
      q: "How do I manage my booking?",
      a:
        "Upon booking and in the lead-up to your clean, you will receive emails that will include a link to either create or sign in to your account with us. Alternatively, you can contact us to request any changes."
    }
  ],
  STORAGE: [
    {
      q: "What can't I store?",
      a:
        "You can store almost anything you want, the only exceptions are: Hazardous and Flammable Materials, Illegal or Stolen Goods, Firearms or Explosives, Foodstuffs or Organic Material.\n\nYou can store anything that is not explosive, flammable, illegal, perishable or environmentally harmful. We are always here to help so don't hesitate to give one of our experts a call if you're not sure."
    },
    {
      q: "Do I need my own lock?",
      a:
        "Yes, you need your own padlock, that way you're guaranteed to be the only person who has a key."
    },
    {
      q:
        "Can I transfer to another space if I find out that the one I am in does not suit my needs?",
      a:
        "Yes you can and your monthly unit rental amount will be altered to suit the size you move into."
    },
    {
      q: "Can someone else access my space?",
      a:
        "Only with written authority from you, we will allow someone else to access your space and so long as they also have a key."
    },
    {
      q: "How does self storage work?",
      a:
        "We requires a minimum 14 days notice if you wish to move out. Any unused rent will be refunded to your bank account once you have moved out.\n\nYou can always change the move-out date; just speak to your self storage facility manager and they'll ensure a smooth process. Please ensure the unit is cleared of all goods and you remove your lock when you move out."
    },
    {
      q: "Are my goods insured?",
      a:
        "While we do everything we can there can be no guarantees in life, and for this reason, if you want your goods insured you can choose to get insurance through us."
    },
    {
      q: "What kind of security is provided?",
      a:
        "Our company has invested heavily in physical security systems and security procedures by our people to add to the security of your belongings while in storage."
    }
  ],
  CLEANING: [
    {
      q: "How do I book a cleaning service?",
      a:
        "You can either book your service through our online form, or email us."
    },
    {
      q: "How many cleaners will come to my house?",
      a:
        "Most of our cleaners work solo, which ensures consistency of service. For larger jobs, we may send a team of cleaners."
    },
    {
      q: "How do I reschedule my clean?",
      a:
        "You can either book your service through our online form, or email us."
    },
    {
      q: "What's included in our flat-rate services?",
      a:
        "All our flat-rate cleans are calculated based on the number of bedrooms and bathrooms you have in your home. Additionally, all living areas, dining, and kitchen areas will be sparkling clean too!\n\n(Any additional rooms such as studies, theatres, home offices are considered as bedrooms.)"
    },
    {
      q: "Can I give the cleaner a key?",
      a:
        "Unfortunately, we no longer accept keys from customers due to security reasons.\n\nWe recommend using a key lock box for security and convenience. Otherwise, you can leave your key and let us know in the booking details."
    },
    {
      q: "What kinds of cleaning services are right for me?",
      a:
        "From daily housekeeping to a regular weekly or fortnightly service or one-off helping hand when the big cleaning jobs are required, we have a number of well-developed services available. Whatever your requirements, we help tailor-make a service to suit. Talk to us about your needs and together, we can work it out."
    },
    {
      q: "Do you bring your own equipment and cleaning products?",
      a:
        "Yes, we bring everything required with us to complete the clean. If you prefer our cleaners to use any of your own equipment or products, just leave a note in instructions during checkout or in your account, and leave it out for our cleaners to use when they arrive."
    },
    {
      q: "Am I required to enter my payment information?",
      a:
        "Yes, we require your payment information prior to scheduling your booking. A hold will be placed on your card the day before your service and you will not be charged until after the cleaning is completed."
    },
    {
      q: "How long it will take to clean my house?",
      a:
        "It all depends on the condition of your home! But as a baseline take the number of bedrooms you have and turn that into hours. \n\nEx. 3 Bedrooms = 3 hours."
    }
  ]
};
